import React, { useEffect } from 'react';
import { Container, Box, Card, CardContent, Grid, Button } from '@material-ui/core';

import classNames from 'classnames';

import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ListAltIcon from '@material-ui/icons/ListAlt';

import Tooltip from '@material-ui/core/Tooltip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Adsense from '../../../../components/Adsense/Adsense';


import Divider from '@material-ui/core/Divider';

import {
    useParams,
    useRouteMatch,
    Switch,
    Route,
    Link
  } from "react-router-dom";

import style from "../../SpreadSheet.module.css";

 function Checkout() {

    useEffect(() => {
        window.scrollTo(0, 0);
  }, []);

    return (
        <Box>
            <Container maxWidth="lg" className={style.spreadsheet_container}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6} lg={5}>
                        <Card className={style.card_info}>
                            <CardContent>
                                <h1 className={style.card_info_title}  style={{marginBottom: 0}}>
                                    {/* <Link to={"/planilhas/cadastro-de-clientes"}>
                                        <Tooltip title="Voltar">
                                            <IconButton aria-label="delete">
                                                <ArrowBackIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Link> */}
                                    Baixar Planilha de Cadastro de Clientes
                                </h1>
                                
                                <Grid container className={style.info_row_price} spacing={5}>
                                    <Grid item className={classNames(style.info_row_label, style.price_label)} lg={3}>Preço</Grid>
                                    <Grid item className={classNames(style.info_row_value, style.price_value)} lg={8}>
                                        <span>R$ 0,00</span>
                                        <span className={style.old_price}>R$ 19,99</span>
                                    </Grid>
                                </Grid>

                                
                        <Grid container spacing={5}>
                            <Grid item md={5} lg={12}>
                                <div style={{textAlign: "center"}}>
                                    <div className={style.ad_container}>
                                        <p>
                                            {/* <ins className="adsbygoogle"
                                                style={{display: "block"}}
                                                data-ad-client="ca-pub-1738781469983163"
                                                data-ad-slot="2409051352"
                                                data-ad-format="auto"
                                                data-full-width-responsive="true">
                                                    
                                            </ins> 
                                            <Adsense ad="checkout" />
                                            */}

                                            <a href="https://go.hotmart.com/W17528064P" target="_blank">
                                            <img src="/ad-excel.png" style={{width: "280px", heigth: "280px"}} />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                                <Grid container spacing={1}>
                                    <Grid item md={5} lg={5}>
                                        <div className={style.button_icon_outlined}>
                                           <a href="/planilhas/cadastro_de_clientes/cadastro.rar" download>
                                                 <Button startIcon={<CloudDownloadRoundedIcon />} size="small"  variant="outlined" color="primary">Baixar Planilha</Button>
                                            </a>
                                        </div>
                                    </Grid>
                                    {/* <Grid item lg={5}>
                                        <div className={style.button_icon_outlined}>
                                           <a href="#">
                                                 <Button startIcon={<ListAltIcon />} size="small" variant="outlined" color="primary">Manual de uso</Button>
                                            </a>
                                        </div>
                                    </Grid> */}
                                </Grid>
                                
                        </CardContent>
                        </Card>

                    </Grid>

                    <Grid item md={5} lg={7}>
                        <div style={{marginBottom: 20}}>
                            <h1 className={style.donation_title}>Apoiadores</h1>

                            <TableContainer component={Paper}>
                                <Table className={style.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nome</TableCell>
                                            <TableCell align="center">Data</TableCell>
                                            <TableCell align="right">Valor</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                       <TableRow>
                                            <TableCell component="th" scope="row">
                                            TARCIZIO L.
                                            </TableCell>
                                            <TableCell align="center">29/06/2021</TableCell>
                                            <TableCell align="right">R$ 11,00</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                            JOSE J. SILVA
                                            </TableCell>
                                            <TableCell align="center">23/04/2021</TableCell>
                                            <TableCell align="right">R$ 5,00</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                            Willian G. de Lima
                                            </TableCell>
                                            <TableCell align="center">17/12/2020</TableCell>
                                            <TableCell align="right">R$ 2,00</TableCell>
                                        </TableRow>

                                         <TableRow>
                                            <TableCell component="th" scope="row">
                                            Antaniel B. da Silva
                                            </TableCell>
                                            <TableCell align="center">09/08/2020</TableCell>
                                            <TableCell align="right">R$ 21,00</TableCell>
                                        </TableRow> 

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                            Josué R. d. Silva
                                            </TableCell>
                                            <TableCell align="center">28/07/2020</TableCell>
                                            <TableCell align="right">R$ 7,00</TableCell>
                                        </TableRow> 

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                            Agnaildo B. d. Santos
                                            </TableCell>
                                            <TableCell align="center">11/07/2020</TableCell>
                                            <TableCell align="right">R$ 11,00</TableCell>
                                        </TableRow> 

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                            André S...
                                            </TableCell>
                                            <TableCell align="center">16/06/2020</TableCell>
                                            <TableCell align="right">R$ 21,00</TableCell>
                                        </TableRow>     

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                            Carlos A C de Souza
                                            </TableCell>
                                            <TableCell align="center">30/04/2020</TableCell>
                                            <TableCell align="right">R$ 15,00</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                            ANA C M G OLIVEIRA
                                            </TableCell>
                                            <TableCell align="center">09/02/2020</TableCell>
                                            <TableCell align="right">R$ 20,00</TableCell>
                                        </TableRow>

                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                            José Oliveira
                                            </TableCell>
                                            <TableCell align="center">12/01/2020</TableCell>
                                            <TableCell align="right">R$ 11,00</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                           </TableContainer>

                           <p>Se você gostou desta planilha, considere fazer um doação 😊</p>
                           <a href="https://pag.ae/bmyYK51" target="_blank" style={{textAlign: "center"}}><input type="image" src="https://stc.pagseguro.uol.com.br/public/img/botoes/doacoes/209x48-doar-assina.gif" name="submit" alt="Pague com PagSeguro - é rápido, grátis e seguro!"/></a>
                        </div>

                        <Carousel showArrows={true}>
                            <div>
                                <img src="/planilhas/cadastro_de_clientes/plan-cad-cli-1.png" />
                            </div>
                            <div>
                                <img src="/planilhas/cadastro_de_clientes/plan-cad-cli-2.png" />
                            </div>
                            <div>
                                <img src="/planilhas/cadastro_de_clientes/plan-cad-cli-3.png" />
                            </div>
                            <div>
                                <img src="/planilhas/cadastro_de_clientes/plan-cad-cli-4.png" />
                            </div>
                        </Carousel>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Checkout;
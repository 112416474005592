import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import classNames from 'classnames';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

import style from './Home.module.css';

function Home() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div>
        <Box className={style.home_info}>
            <Box className={style.home_info_container}>

                <Container maxWidth="lg" >
                    <Grid container className={style.home_info_title} alignItems="center" justify="center">
                        <Grid item lg={6}>
                            <h1>Soluções para impulsionar seu negócio</h1>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" justify="center">
                        <Grid item lg={6} className={style.home_info_title_description} >
                            <p>
                                <i>
                                    Softwares profissionais para impulsionar o crescimento do seu negócio utilizando o que tem de mais atual e avançado em tecnologia
                                </i>
                            </p>
                        </Grid>
                    </Grid>

                    <Grid container alignItems="center" justify="center">
                        <Grid item lg={6} className={style.home_info_buttons}>
                            <Link to={`/planilhas`}>
                                <p>
                                    <Button variant="contained" className={style.first_action_button}>Ver Planilhas</Button>
                                
                                </p>
                            </Link>
                        </Grid>
                    </Grid>
                    

                </Container>

            </Box>
        </Box>

        <Box className={style.home_section}>
            <Container maxWidth="lg">
                <h1 className={style.section_title}>Veja nossas planilhas</h1>
                <p className={style.section_description}>
                Nossas planilhas são desenvolvidas com vistas a suprir as principais necessidades dos nossos clientes, para isso utilizamos o VBA (Visual Basic for Application) o que torna as nossas soluções ainda mais profissionais, rápidas e eficientes.
                </p>

                <Grid container>
                    <Grid item xs={12} sm={5} md={5} lg={3}>
                        <Link className={style.spreadsheet_link}  
                        to={"/planilhas/cadastro-de-clientes"}>
                        <Card className={style.section_card}>
                            <CardActionArea>
                                <CardMedia
                                    className={style.media}
                                    image="/planilha_cadastro_clientes.jpg"
                                    title="Planilha de Cadastro de Clientes"
                                />
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Cadastro de Clientes
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Cadastre as informações dos clientes no Excel
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                            </Card>
                            </Link>
                    </Grid>
                </Grid>
            </Container>
        </Box>

        <Box className={classNames(style.home_section, style.section_articles)}>
            <Container maxWidth="lg">
                <h1 className={style.section_title}>Artigos</h1>
 
                <Grid container>
                    <Grid item xs={12} sm={5} md={5} lg={3}>
                        <Link className={style.spreadsheet_link}  
                        to={"/artigos/como-utilizar-modelos-prontos-do-excel"}>
                        <Card className={style.section_card}>
                            <CardActionArea>
                                <CardMedia
                                    className={style.media}
                                    image="/modelos_office.jpg"
                                    title="Modelos de planilhas prontos"
                                />
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                   Como utilizar modelos prontos do Excel
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                   Saiba como utilizar os modelos prontos do office...
                                </Typography>
                                </CardContent>
                            </CardActionArea>
                            </Card>
                            </Link>
                    </Grid>
                </Grid>
            </Container>
        </Box>

        </div>
    )

}

export default Home;
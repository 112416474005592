import React, { useEffect } from 'react';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Divider from '@material-ui/core/Divider';

import Button from '@material-ui/core/Button';

import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';

import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';
import classNames from 'classnames';

import Adsense from '../../../../components/Adsense/Adsense';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


import style from '../Post.module.css';

import {
    useParams,
    useRouteMatch,
    Switch,
    Route,
    Link
  } from "react-router-dom";

function ModelosProntos() {

  useEffect(() => {
        window.scrollTo(0, 0);
  }, []);

  let { path, url } = useRouteMatch();

  let { code } = useParams();

  return (
    <Box>
    <Container maxWidth="lg" className={style.article_container}>
        <Grid container spacing={5}>
            <Grid item xs={12} sm={12} lg={8} md={8}>

                <Card className={style.card_info}>
                <CardContent>
                    <h1 className={style.post_title}>Como utilizar modelos prontos do Excel</h1>
                    <h2 className={style.post_info_title}>Publicado em: 09/09/2021</h2>


                    <p>Se você utiliza as versões mais recentes do Excel como 2013, 2016 ou 2019, existe uma funcionalidade do Excel na qual é possível selecionar e utilizar modelos já prontos.</p>
                
                    <h2 className={style.card_info_subtitle}>Por que utilizar modelos prontos e não criar do zero uma planilha?</h2>

                    <p>Em alguns casos um modelo pronto vai garantir uma economia enorme de tempo ou até mesmo salvar sua vida caso precise fazer uma planilha para um projeto ou uma reunião.</p>
              
                    <p>Nesse artigo vamos mostrar como utilizar essa funcionalidade do Excel. Para isso, utilizaremos a versão 2019 integrante do pacote <a target="_blank" href="https://www.office.com/">office 365</a>.</p>

                    <h2 className={style.card_info_subtitle}>Mão na massa</h2>

                    <p>Para seguir esse passo-a-passo é necessário que você tenha instalado em seu computador o Excel 2013, 2016 ou 2019. As versões online do office 365 também são compatíveis, entretanto as imagens que apresentaremos no decorrer do artigo podem ser diferentes para essas versões.</p>

                    <p>O primeiro passo é abrir o MS Excel, podemos fazer isso digitando Excel na barra de pesquisa do seu sistema operacional. A Imagem a seguir mostrar a tela principal do Excel após ele ser carregado.</p>


                    <Zoom>
                      <img
                        alt="Tela inicial do Excel"
                        src="/artigos/como-utilizar-modelos-prontos-do-excel/1.jpg"
                        width="100%"
                      />
                    </Zoom>

                    <p>Em <span className={style.indicator}>1</span> temos as planilhas mais recentes que você criou, esse é um atalho ótimo quando você deseja abrir uma planilha recente mais não se recorda em qual pasta do seu computador essa planilha foi salva. Na parte <span className={style.indicator}>2</span>, temos a opção <b>Mais modelos</b>, basta clicar nessa opção para o Excel exibir a tela de seleção de modelos. A imagem a seguir mostra a tela que será aberta com as miniaturas dos modelos após o clique na opção <span className={style.indicator}>2</span>.</p>


                    <Zoom>
                      <img
                        alt="Tela inicial do Excel"
                        src="/artigos/como-utilizar-modelos-prontos-do-excel/2.jpg"
                        width="100%"
                      />
                    </Zoom>

                    <p>Como podemos ver em <span className={style.indicator}>1</span>, temos uma barra de pesquisa onde podemos escrever as palavras-chave que indicam qual categoria de planilha desejamos criar. Por exemplo, digite Folha de ponto na barra de pesquisa <span className={style.indicator}>1</span> e tecle ENTER no teclado, algo semelhante a Imagem a seguir deve aparecer.</p>

                    <Zoom>
                      <img
                        alt="Tela inicial do Excel"
                        src="/artigos/como-utilizar-modelos-prontos-do-excel/3.jpg"
                        width="100%"
                      />
                    </Zoom>

                    <p>A região destacada <span className={style.indicator}>1</span> mostra as miniaturas dos modelos de planilhas que correspondem a palavra-chave Folha de Ponto que pesquisamos. Observe que existe uma barra de rolagem <span className={style.indicator}>2</span> que podemos utilizar para visualizar os demais modelos retornados pela pesquisa. Vamos selecionar o primeiro modelo retornado, para isso dê um clique na primeira miniatura <span className={style.indicator}>3</span>. A seguinte janela com informações sobre o modelo deve aparecer:</p>

                    <Zoom>
                      <img
                        alt="Tela inicial do Excel"
                        src="/artigos/como-utilizar-modelos-prontos-do-excel/4.jpg"
                        width="100%"
                      />
                    </Zoom>

                    <p>Em <span className={style.indicator}>1</span> temos uma pré-visualização da planilha, que é uma ampliação da miniatura apresentada anteriormente. Além disso, em <span className={style.indicator}>2</span> temos o nome da planilha, o fornecedor (o criador da planilha) e uma breve descrição a respeito das funcionalidades da planilha. Temos a opção de clicar em <span className={style.indicator}>3</span> e carregar o modelo no Excel, ou então clicar em <span className={style.indicator}>4</span> e navegar pelos demais modelos de planilhas de Folha de Ponto. Em nosso caso, clicaremos em <span className={style.indicator}>3</span>. Logo em seguida, a planilha deverá ser carregada no Excel, estando pronta para ser utilizada conforme suas necessidades. Veja na imagem a seguir o resultado dos passos que realizamos.</p>

                    <Zoom>
                      <img
                        alt="Tela inicial do Excel"
                        src="/artigos/como-utilizar-modelos-prontos-do-excel/5.jpg"
                        width="100%"
                      />
                    </Zoom>

                    <p>Possivelmente você ficou curioso em saber como são as demais planilhas de Folha de Ponto que apareceram na pesquisa. Geralmente, o processo de procura de um modelo de planilha não termina na primeira tentativa, então, aproveite esse momento e realize os passos novamente e teste mais opções de modelos. E claro, pesquise também por modelos que atendam suas necessidades e de sua empresa.</p>


                </CardContent>
                </Card>

            </Grid>

            <Grid item xs={12} sm={12} lg={4} md={4}>
                <Card className={classNames(style.sticky, style.card_info)}>
                    <CardContent>
                        <Grid container spacing={5}>
                            <Grid item lg={12}>
                                <div style={{textAlign: "center"}}>
                                    <div className={style.ad_container}>
                                        <p>
                                        <Adsense ad="product_sidebar" />
                                        </p>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Container>
</Box>
  );
}

export default ModelosProntos;
import React, { useState, useEffect}  from 'react';
import logo from './logo.svg';

// Header
import Header from './components/Header/Header';

// Footer
import Footer from './components/Footer/Footer';

// Pages
// Home
import Home from './pages/Home/Home';
// Contact
import Contact from './pages/Contact/Contact';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";

import ReactGA from 'react-ga';

import './App.css';
import SpreadSheet from './pages/SpreadSheet/SpreadSheet';
import Article from './pages/Article/Article';


function App() {

  const location = useLocation();

  React.useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location]);  

  React.useEffect(() => {
    ReactGA.initialize('UA-192814792-2', {
      debug: false
    });
  }, []); 

  return (
      <div className="App">
        
          <Header />

          <Switch>
            <Route exact path={["/index.html", "/"]}>
               <Home />
            </Route>

            <Route exact path="/contato">
               <Contact />
            </Route>

            <Route path="/planilhas/">
               <SpreadSheet />
            </Route>

            <Route path="/artigos/">
               <Article />
            </Route>

          </Switch>

          <Footer />
      </div>
  );
}

export default App;

import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import MailOutlineIcon from '@material-ui/icons/MailOutline';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

import style from './Footer.module.css';

function Footer() {

    return (
        <Box className={style.footer_top}>
             <Container className={style.footer_top_container} maxWidth="lg">
                  <Grid container spacing={10}>
                      <Grid item className={style.footer_top_left} sm={12} md={5} lg={5}>
                        <Link to={`/`}>
                            <img src="/tudoffice_intro_white.png" alt="tudoffice logo" width="170" height="30" className={style.footer_top_left_logo} />
                        </Link>
                        <p>Softwares profissionais para impulsionar o crescimento do seu negócio utilizando o que tem de mais atual e avançado em tecnologia.</p>
                      </Grid>

                      <Grid item sm={12} md={3} lg={3}>
                          <h1 className={style.footer_top_center_title}>Links Úteis</h1>
                          <ul className={style.footer_top_center_content}>
                              <li><Link to={`/`}>Início</Link></li>
                              <li><Link to={`/planilhas`}>Planilhas</Link></li>
                              <li><Link to={`/artigos`}>Artigos</Link></li>
                          </ul>
                      </Grid>

                      <Grid item sm={12} md={3} lg={3} className={style.footer_top_right}>
                          <h1 className={style.footer_top_right_title}>Contatos</h1>

                          <p>
                            <MailOutlineIcon />
                            <span className={style.mail}>
                                <a href="#">contato@tudoffice.com</a>
                            </span>
                          </p>

                          <div className={style.action_button}>
                              <Link to={`/contato`}>
                                <Button variant="contained" color="primary">Enviar uma Mensagem</Button>
                               </Link>
                          </div>
                      </Grid>

                  </Grid> 
                   
             </Container>
        </Box>


    )

}

export default Footer;
import React, { useEffect } from 'react';

function Adsense(props) {

    useEffect(() => {
            window.scrollTo(0, 0);
             let adsbygoogle = [];
             (adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    const loadAd = () => {

        switch(props.ad) {
        
            case "checkout": 
                return (
                    <ins className="adsbygoogle"
                         style={{display: "block"}}
                         data-ad-client="ca-pub-1738781469983163"
                         data-ad-slot="2409051352"
                         data-ad-format="auto"
                         data-full-width-responsive="true">                            
                     </ins>                    
                 )
            case "product_sidebar": 
                return (
                    <ins className="adsbygoogle"
                        style={{display: "block"}}
                        data-ad-client="ca-pub-1738781469983163"
                        data-ad-slot="6819263173"
                        data-ad-format="auto"
                        data-full-width-responsive="true">
                    </ins>
                )
        }

    }

    return (
        loadAd()
    )

}


export default Adsense;
import React from 'react';

import {
    useParams,
    useRouteMatch,
    Switch,
    Route,
  } from "react-router-dom";


import ModelosProntos from './ModelosProntos/ModelosProntos';

function Post() {

  let { path, url } = useRouteMatch();

  let { code } = useParams();

  const productToRender = () => {
      switch(code) {
          case "como-utilizar-modelos-prontos-do-excel": return (<ModelosProntos />)
      }
  }

  return (
    <div>
      {productToRender()}   
    </div>
  );
}

export default Post;
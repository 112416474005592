import React, { useEffect } from 'react';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Divider from '@material-ui/core/Divider';

import Button from '@material-ui/core/Button';

import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';

import Icon from '@material-ui/core/Icon';
import SaveIcon from '@material-ui/icons/Save';

import classNames from 'classnames';

import ReactDOM from 'react-dom';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Checkout from './Checkout';
import Adsense from '../../../../components/Adsense/Adsense';
 

import style from '../../SpreadSheet.module.css';


import {
    useParams,
    useRouteMatch,
    Switch,
    Route,
    Link
  } from "react-router-dom";

function CadastroCliente() {

  useEffect(() => {
        window.scrollTo(0, 0);
  }, []);

  let { path, url } = useRouteMatch();

  let { code } = useParams();

  return (
        <Box>
            <Container maxWidth="lg" className={style.spreadsheet_container}>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={12} lg={7} md={7}>
                        <Carousel showArrows={true}>
                            <div>
                                <img src="/planilhas/cadastro_de_clientes/plan-cad-cli-1.png" />
                            </div>
                            <div>
                                <img src="/planilhas/cadastro_de_clientes/plan-cad-cli-2.png" />
                            </div>
                            <div>
                                <img src="/planilhas/cadastro_de_clientes/plan-cad-cli-3.png" />
                            </div>
                            <div>
                                <img src="/planilhas/cadastro_de_clientes/plan-cad-cli-4.png" />
                            </div>
                        </Carousel>

                        <Card className={style.card_info}>
                        <CardContent>
                            <h1 className={style.card_info_title}>Planilha de Cadastro de Clientes</h1>
                            <p>A planilha de cadastro de clientes é perfeita para seu negócio, com ela é possível cadastrar e editar as informações dos seus clientes sempre que necessário e de maneira rápida, fácil e bastante intuitiva, sem que seja necessário conhecimentos avançados em Excel ou programação (VBA). Desenvolvemos a planilha de cadastro de clientes pensando em você que busca uma forma facil e segura de armazenar os contatos dos seus clientes para registro de vendas e anotações e também para realizar contatos futuros com ofertas de novos produtos e serviços.</p>
                            <p>Desenvolvemos uma solução capaz de manter as informações dos seus clientes bem localizadas e de fácil acesso por meio de busca avançada, assim seu negócio será capaz de buscá-las a qualquer momento e com o histórico de dados, vendas e interações registradas, sua empresa pode oferecer produtos, serviços, promoções, descontos, brindes e muito mais.</p>
                        </CardContent>
                        </Card>

                        <div className={style.resp_container}>
                            <iframe className={style.resp_iframe} width="560" height="315" src="https://www.youtube.com/embed/pNB3hh3HDiI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                        </div>

                    </Grid>

                    <Grid item xs={12} sm={12} lg={4} md={4}>
                        <Card className={classNames(style.sticky, style.card_info)}>
                            <CardContent>
                                <h1 className={style.card_info_title}>Informações</h1>
                                <Grid container spacing={5}>
                                    <Grid item lg={5} className={style.info_row_label}>Criado em</Grid>
                                    <Grid item lg={7} className={style.info_row_value}>14 de março de 2018</Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item lg={5} className={style.info_row_label}>Atualizado em</Grid>
                                    <Grid item lg={7} className={style.info_row_value}>13 de abril de 2020</Grid>
                                </Grid>

                                <Grid container className={style.info_row_price} spacing={5}>
                                    <Grid item className={classNames(style.info_row_label, style.price_label)} lg={3}>Preço</Grid>
                                    <Grid item className={classNames(style.info_row_value, style.price_value)} lg={8}>
                                        <span>R$ 0,00</span>
                                        <span className={style.old_price}>R$ 19,99</span>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={5}>
                                    <Grid item lg={12}>
                                        <div className={style.action_button}>
                                        <Link to={`${url}/checkout`}>
                                            <Button startIcon={<CloudDownloadRoundedIcon />}  variant="contained" color="primary">Baixar Planilha</Button>
                                        </Link>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Divider />

                                <Grid container spacing={5}>
                                    <Grid item lg={12}>
                                        <div style={{textAlign: "center"}}>
                                            <div className={style.ad_container}>
                                                <p>
                                                <Adsense ad="product_sidebar" />
                                                </p>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
  );
}

export default CadastroCliente;
import React, { useEffect } from 'react';
import { Container, Box, Card, CardContent, Grid, Button } from '@material-ui/core';

import classNames from 'classnames';

import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ListAltIcon from '@material-ui/icons/ListAlt';

import Tooltip from '@material-ui/core/Tooltip';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";


import Divider from '@material-ui/core/Divider';

import {
    useParams,
    useRouteMatch,
    Switch,
    Route,
    Link
  } from "react-router-dom";

import style from "../../SpreadSheet.module.css";

 function Manual() {

    useEffect(() => {
        window.scrollTo(0, 0);
  }, []);

    return (
        <Box>
            <Container maxWidth="lg" className={style.spreadsheet_container}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={12} lg={7}>

                    <h1 className={style.donation_title}>Manual de utilização</h1>

                    <div>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography>Habilitando as macros da planilha</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                A planilha de cadastro de clientes foi desenvolvida com códigos de programação, por isso é necessário que o usuário habilite a execução desses códigos para a planilha realizar as rotinas automatizadas.
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                        <ExpansionPanel>
                            <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography>Editando os campos da planilha</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                            <Typography>
                                A planilha de cadastro de clientes foi desenvolvida com códigos de programação, por isso é necessário que o usuário habilite a execução desses códigos para a planilha realizar as rotinas automatizadas.
                            </Typography>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                     </div>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Manual;
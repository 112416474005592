import React, {useState, useEffect, useRef} from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Typography from '@material-ui/core/Typography';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Product from './Product/Product';
import Manual from './Product/CadastroCliente/Manual';

import {
    BrowserRouter as Router,
    useRouteMatch,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

import style from './SpreadSheet.module.css';
import Checkout from './Product/CadastroCliente/Checkout';

function SpreadSheet() {

    let { path, url } = useRouteMatch();

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
       <Switch>

        <Route exact  path={`${path}`}>

            <Box className={style.spreadsheet_breadcrumb_box}>
                <Container maxWidth="lg">
                        <h1>Planilhas</h1>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link to={`/`}>
                                <Typography color="textPrimary">Início</Typography>
                            </Link>
                            <Typography color="textPrimary">Planilhas</Typography>
                        </Breadcrumbs>
                </Container>
            </Box>

            <Box className={style.home_section}>
                <Container maxWidth="lg" className={style.spreadsheet_container}>

                    <Grid container>
                        <Grid item xs={12} sm={5} md={5} lg={3}>
                           <Link to={`${path}cadastro-de-clientes`} className={style.spreadsheet_link}>
                            <Card className={style.section_card}>
                                <CardActionArea>
                                    <CardMedia
                                        className={style.media}
                                        image="/planilha_cadastro_clientes.jpg"
                                        title="Planilha de Cadastro de Clientes"
                                    />
                                    <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Cadastro de Clientes
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        Cadastre as informações dos clientes no Excel
                                    </Typography>
                                    </CardContent>
                                </CardActionArea>
                                </Card>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Route>

        <Route exact path={`${path}:code`}>
            <Product />
        </Route>

        <Route exact path={`${path}:code/checkout`}>
            <Checkout />
        </Route>

        <Route exact path={`${path}:code/manual`}>
            <Manual />
        </Route>

        </Switch>
    )

}

export default SpreadSheet;
import React, {useState, useEffect, useRef} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Container from '@material-ui/core/Container';

import axios from 'axios';

import FormHelperText from '@material-ui/core/FormHelperText';

import Alert from '@material-ui/lab/Alert';

import TextField from '@material-ui/core/TextField';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import style from './Contact.module.css';

function Contact() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const inputName = useRef(null);
    const inputEmail = useRef(null);
    const inputSubject = useRef(null);
    const inputMessage = useRef(null);
    
    const [formStatus, setFormStatus] = useState(true);

    const [formInput, setFormInput] = useState({
       name: "",
       email: "",
       subject: "",
       message: "" 
    });

    const [formValidation, setFormValidation] = useState({
        name: false,
        email: false,
        subject: false,
        message: false  
     });

    const checkValidation = (e) => {
        setFormValidation({
            ...formValidation,
            [e.target.name]: false
        })
    }

    const onChange = (e) => {
        checkValidation(e);
        setFormInput({
            ...formInput,
            [e.target.name]: e.target.value
        })
    }

    const onSubmit = (e) => {

        let error = false;

        let errors = {};

        if(formInput.name == "") { errors.name = true; error = true; }
        if(formInput.email == "") { errors.email = true; error = true; }
        if(formInput.subject == "") { errors.subject = true; error = true; }
        if(formInput.message == "") { errors.message = true; error = true; }

        if(formInput.message == "") inputMessage.current.focus();
        if(formInput.subject == "") inputSubject.current.focus();
        if(formInput.email == "") inputEmail.current.focus();
        if(formInput.name == "") inputName.current.focus();
        
        setFormValidation({...formValidation, ...errors});

        if(error) return;

        var formData = new FormData();

        formData.append('name', formInput.name);
        formData.append('subject', formInput.subject);
        formData.append('email', formInput.email);
        formData.append('message', formInput.message);

        axios.post('/sendemail.php', formData, { 'content-type': 'multipart/form-data' })   
        .then(response => {  
           setFormStatus(false); 
        })
        .catch(error => {
            setFormStatus(true); 
        })
    }

    const closeAlert = () => {
        setFormInput({
            name: "",
            email: "",
            subject: "",
            message: "" 
        })
        setFormStatus(true);
    }

    return (

        <Box className={style.contact_form}>
            <Container maxWidth="lg">
                 <h1 className={style.section_title}>Envie para nós uma mensagem</h1>
                 <p className={style.section_description}>
                     Preencha os campos abaixo com suas necessidades, dúvidas ou sugestões. Responderemos em breve !
                 </p>
            </Container>

            {!formStatus &&
                <Container maxWidth="lg">
                    <Alert severity="success" onClose={closeAlert}>Contato enviado com sucesso !</Alert>
                </Container>
            }

            {formStatus &&
                <Container maxWidth="lg">
                    <form>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextField 
                                    inputRef={inputName} 
                                    id="outlined-basic" 
                                    helperText={formValidation.name ? "Por favor, digite seu nome." : ""} 
                                    error={formValidation.name} 
                                    value={formInput.name} 
                                    onChange={onChange} name="name" 
                                    label="Nome" 
                                    variant="outlined" 
                                    className={style.text_field}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextField 
                                    id="outlined-basic" 
                                    inputRef={inputEmail} 
                                    helperText={formValidation.email ? "Por favor, digite seu E-mail." : ""} 
                                    error={formValidation.email} 
                                    value={formInput.email} 
                                    onChange={onChange} name="email" 
                                    label="E-mail" 
                                    variant="outlined" 
                                    className={style.text_field}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <TextField 
                                    id="outlined-basic" 
                                    inputRef={inputSubject} 
                                    helperText={formValidation.subject ? "Por favor, digite o assunto da menssagem." : ""} 
                                    error={formValidation.subject} 
                                    value={formInput.subject} 
                                    onChange={onChange} 
                                    name="subject" 
                                    label="Assunto" 
                                    variant="outlined" 
                                    className={style.text_field}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <TextField 
                                    id="outlined-basic" 
                                    inputRef={inputMessage} 
                                    helperText={formValidation.message ? "Por favor, digite sua menssagem." : ""} 
                                    error={formValidation.message} 
                                    value={formInput.message} 
                                    onChange={onChange} 
                                    label="Mensagem" 
                                    variant="outlined" 
                                    name="message"
                                    multiline={true} 
                                    rows={10} 
                                    className={style.text_field_multiline}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className={style.action_button}>
                                    <Button variant="contained" color="primary" onClick={onSubmit}>Enviar</Button>
                                </div>
                            </Grid>
                        </Grid>


                    </form>
                </Container>
            }
        </Box>
    )

}

export default Contact;
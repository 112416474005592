import React from 'react';

import {
    useParams,
    useRouteMatch,
    Switch,
    Route,
  } from "react-router-dom";

import CadastroCliente from './CadastroCliente/CadastroCliente.js';

function Product() {

  let { path, url } = useRouteMatch();

  let { code } = useParams();

  const productToRender = () => {
      switch(code) {
          case "cadastro-de-clientes": return (<CadastroCliente />)
      }
  }

  return (
    <div>
      {productToRender()}   
    </div>
  );
}

export default Product;
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Container from '@material-ui/core/Container';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useLocation
  } from "react-router-dom";

import style from './Header.module.css';

function Header() {

    return (

        <div className={style.wrap_stricky}>
            <AppBar position="fixed" className={style.header_info}>
                <Container>
                    <Toolbar className={style.website_toolbar}>

                        <Typography>
                            <Link to={`/`}>
                                <img src="/tudoffice_intro.png" alt="tudoffice logo" width="150" heigth="30" />
                            </Link>
                        </Typography>

                        <div className={style.icon_menu}></div>
                        <ul className={style.header_menu}>
                            <li className={style.active}><Link to={`/`}>Início</Link></li>
                            <li><Link to={`/planilhas`}>Planilhas</Link></li>
                            <li><Link to={`/artigos`}>Artigos</Link></li>
                            <li><Link to={`/contato`}>Contato</Link></li>
                        </ul>

                    </Toolbar>
                 </Container>
            </AppBar>
        </div>
    )

}

export default Header;